export const specialTypesOfErrors = {
  accessExpired: 'accessExpired',
  apiReturnsAnErrorMessage: 'apiReturnsAnErrorMessage'
};

export const errorMapping = {
  listApprovalError: {
    listing: {
      401: specialTypesOfErrors.accessExpired,
      default: 'error.listApprovalError'
    }
  },

  login: {
    access: {
      401: 'error.invalidUsernamePassword',
      default: 'error.loginError'
    }
  },

  getMe: {
    get: {
      401: specialTypesOfErrors.accessExpired,
      default: 'error.onLoadedFetchMe'
    }
  },

  spreadsheetError: {
    create: {
      401: specialTypesOfErrors.accessExpired,
      400: specialTypesOfErrors.apiReturnsAnErrorMessage,
      204: 'error.specificCpfnotFound',
      default: 'error.createSpreadsheet'
    },
    read: {
      401: specialTypesOfErrors.accessExpired,
      400: specialTypesOfErrors.apiReturnsAnErrorMessage,
      default: 'error.lotQuery'
    },
    upload: {
      401: specialTypesOfErrors.accessExpired,
      400: specialTypesOfErrors.apiReturnsAnErrorMessage,
      default: 'error.uploadSpreadsheet'
    },
    downloadList: {
      default: 'error.downloadListSpreadsheet'
    }
  },

  embedLooker: {
    get: {
      401: specialTypesOfErrors.accessExpired,
      400: specialTypesOfErrors.apiReturnsAnErrorMessage,
      default: 'error.embedReport'
    }
  },

  approvalStudent: {
    updateStudent: {
      401: specialTypesOfErrors.accessExpired,
      400: specialTypesOfErrors.apiReturnsAnErrorMessage,
      pendente: 'error.pendingStudentError',
      reprovado: 'error.declineStudentError',
      aprovado: 'error.approveStudentError',
      default: 'error.genericStudentError'
    }
  },

  extractCession: {
    get: {
      401: specialTypesOfErrors.accessExpired,
      default: 'error.genericExtractCessionError'
    }
  },

  iesAvaliable: {
    get: {
      401: specialTypesOfErrors.accessExpired,
      default: 'error.iesOnLoad'
    }
  },
  fiscalDocuments: {
    list: {
      get: {
        401: specialTypesOfErrors.accessExpired,
        default: 'error.onLoadListFiscalDocuments'
      }
    },
    download: {
      repasseImpostos: {
        get: {
          401: specialTypesOfErrors.accessExpired,
          default: 'error.onDownloadRepasseImpostos'
        }
      }
    },

    notaFiscal: {
      downloadHistoric: {
        get: {
          401: specialTypesOfErrors.accessExpired,
          default: 'error.onGetHistoricDownloadNotaFiscal'
        }
      }
    }
  },
  iesInformation: {
    campus: {
      get: {
        401: specialTypesOfErrors.accessExpired,
        default: 'error.onLoadCampus'
      }
    },
    courses: {
      get: {
        401: specialTypesOfErrors.accessExpired,
        default: 'error.onLoadCourses'
      }
    },

    periods: {
      get: {
        401: specialTypesOfErrors.accessExpired,
        default: 'error.onLoadPeriods'
      }
    },
    students: {
      get: {
        401: specialTypesOfErrors.accessExpired,
        default: 'error.onLoadStudents'
      }
    },
    groups: {
      get: {
        401: specialTypesOfErrors.accessExpired,
        default: 'error.onLoadGroups'
      }
    }
  },

  statusAvaliable: {
    get: {
      default: 'error.onLoadedStatusAvaliable'
    }
  },
  students: {
    get: {
      documents: 'error.onLoadDocuments',
      401: specialTypesOfErrors.accessExpired,
      400: specialTypesOfErrors.apiReturnsAnErrorMessage
    },
    contracts: {
      default: 'error.onLoadContracts',
      401: specialTypesOfErrors.accessExpired,
      400: specialTypesOfErrors.apiReturnsAnErrorMessage
    }
  },
  calendar: {
    get: {
      default: 'error.onLoadCalendar',
      401: specialTypesOfErrors.accessExpired,
      400: specialTypesOfErrors.apiReturnsAnErrorMessage
    }
  },
  avaliationHistory: {
    post: {
      default: 'error.requestAvaliation',
      401: specialTypesOfErrors.accessExpired,
      400: specialTypesOfErrors.apiReturnsAnErrorMessage
    },
    get: {
      default: 'error.onLoadAvaliation',
      401: specialTypesOfErrors.accessExpired,
      400: specialTypesOfErrors.apiReturnsAnErrorMessage
    }
  },
  evaluator: {
    get: {
      default: 'error.onLoadEvaluators',
      401: specialTypesOfErrors.accessExpired,
      400: specialTypesOfErrors.apiReturnsAnErrorMessage
    }
  },
  users: {
    get: {
      default: 'error.onLoadUserData',
      401: specialTypesOfErrors.accessExpired,
      400: specialTypesOfErrors.apiReturnsAnErrorMessage
    }
  }
};
