import { NEXT_PUBLIC_INSIGHTS_SCREEN_IS_ENABLED } from '@/constants/envs';
import { routesNames } from '@/constants/routesNames';
import { IRoleApi } from '@/interfaces/auth/IRoleApi';

type PermissionType = {
  action: string;
  allowedUri: string;
};

export interface IPermissionsFactory {
  role: string;
  roleId: string;
  totalSchools?: number;
  permissions: PermissionType[];
}

export default class PermissionsFactory {
  static builder(data: IRoleApi[]): IPermissionsFactory[] {
    const permissions: IPermissionsFactory[] = [];

    data?.forEach((role) => {
      if (permissions.find((permission) => permission.role === role.name)) return;
      const body: IPermissionsFactory = {
        role: role.name,
        roleId: role.id,
        permissions: [],
        totalSchools: role?.schools?.length || 0
      };
      role.permissions.forEach((permission) => {
        body.permissions.push({
          action: permission.name,
          allowedUri: permission.allowedUri?.split('?')[0] || ''
        });
      });
      body.permissions.push(...PermissionsFactory.publicPermissions);
      permissions.push(body);
    });

    return permissions;
  }

  static get publicPermissions(): PermissionType[] {
    const permissions = [
      {
        action: 'access my account',
        allowedUri: routesNames.myAccount
      },
      {
        action: 'access user management',
        allowedUri: routesNames.userManagement
      },
      {
        action: 'access create new user screen',
        allowedUri: routesNames.createUser
      },
      {
        action: 'access_search_page_faq',
        allowedUri: routesNames.helpdeskSearch
      }
    ];

    if (NEXT_PUBLIC_INSIGHTS_SCREEN_IS_ENABLED) {
      permissions.push({
        action: 'access insights',
        allowedUri: routesNames.insights
      });
    }

    return permissions;
  }
}
