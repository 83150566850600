import { ScreenNamesEnum } from '@/interfaces/ISidebarMenu';
import { routesNames } from '@/constants/routesNames';
import { routeScreensModelType } from '@/interfaces/routeScreensModelType';
import { NEXT_PUBLIC_INSIGHTS_SCREEN_IS_ENABLED } from '@/constants/envs';

const firstScreen = NEXT_PUBLIC_INSIGHTS_SCREEN_IS_ENABLED
  ? {
      routeName: routesNames.insights,
      screenName: ScreenNamesEnum.insights,
      childFrom: null,
      index: 0
    }
  : {
      routeName: routesNames.dashboard,
      screenName: ScreenNamesEnum.dashboard,
      childFrom: null,
      index: 0
    };

export const routeScreensModel: routeScreensModelType[] = [
  firstScreen,
  {
    routeName: null,
    screenName: ScreenNamesEnum.approval,
    childFrom: null,
    index: 1
  },
  {
    routeName: routesNames.individualApproval,
    screenName: ScreenNamesEnum.individualApproval,
    childFrom: ScreenNamesEnum.approval,
    index: 2
  },
  {
    routeName: routesNames.approvalBatch,
    screenName: ScreenNamesEnum.approvalBatch,
    childFrom: ScreenNamesEnum.approval,
    index: 3
  },
  {
    routeName: routesNames.avaliationHistory,
    screenName: ScreenNamesEnum.avaliationHistory,
    childFrom: ScreenNamesEnum.approval,
    index: 4
  },
  {
    routeName: routesNames.students,
    screenName: ScreenNamesEnum.students,
    childFrom: null,
    index: 5
  },
  {
    routeName: routesNames.studentsWallet,
    screenName: ScreenNamesEnum.studentsWallet,
    childFrom: ScreenNamesEnum.students,
    index: 6
  },
  {
    routeName: routesNames.searchStudents,
    screenName: ScreenNamesEnum.searchStudents,
    childFrom: ScreenNamesEnum.students,
    index: 7
  },
  {
    routeName: routesNames.contractEstimate,
    screenName: ScreenNamesEnum.contractEstimate,
    childFrom: ScreenNamesEnum.students,
    index: 8
  },
  {
    routeName: routesNames.financial,
    screenName: ScreenNamesEnum.financial,
    childFrom: null,
    index: 9
  },
  {
    routeName: routesNames.antecipation,
    screenName: ScreenNamesEnum.financialAntecipation,
    childFrom: ScreenNamesEnum.financial,
    index: 10
  },
  {
    routeName: routesNames.antecipationExtract,
    screenName: ScreenNamesEnum.antecipationExtract,
    childFrom: ScreenNamesEnum.financialAntecipation,
    index: 11
  },
  {
    routeName: routesNames.financial,
    screenName: ScreenNamesEnum.financialManagement,
    childFrom: ScreenNamesEnum.financial,
    index: 12
  },

  {
    routeName: routesNames.transferEstimate,
    screenName: ScreenNamesEnum.transferEstimate,
    childFrom: ScreenNamesEnum.financialManagement,
    index: 13
  },
  {
    routeName: routesNames.transferExtractManagement,
    screenName: ScreenNamesEnum.transferExtractManagement,
    childFrom: ScreenNamesEnum.financialManagement,
    index: 14
  },
  {
    routeName: routesNames.woReport,
    screenName: ScreenNamesEnum.woReport,
    childFrom: ScreenNamesEnum.financialManagement,
    index: 15
  },
  {
    routeName: routesNames.grantedManagement,
    screenName: ScreenNamesEnum.grantedManagement,
    childFrom: ScreenNamesEnum.financial,
    index: 16
  },
  {
    routeName: routesNames.grantedManagementEstimate,
    screenName: ScreenNamesEnum.grantedManagementEstimate,
    childFrom: ScreenNamesEnum.grantedManagement,
    index: 17
  },
  {
    routeName: routesNames.grantedManagementExtract,
    screenName: ScreenNamesEnum.grantedManagementExtract,
    childFrom: ScreenNamesEnum.grantedManagement,
    index: 18
  },
  {
    routeName: routesNames.easyInstallment,
    screenName: ScreenNamesEnum.easyInstallment,
    childFrom: ScreenNamesEnum.financial,
    index: 19
  },
  {
    routeName: routesNames.easyInstallmentEstimate,
    screenName: ScreenNamesEnum.easyInstallmentEstimate,
    childFrom: ScreenNamesEnum.easyInstallment,
    index: 20
  },
  {
    routeName: routesNames.easyInstallmentExtract,
    screenName: ScreenNamesEnum.easyInstallmentExtract,
    childFrom: ScreenNamesEnum.easyInstallment,
    index: 21
  },

  {
    routeName: routesNames.operationalsDates,
    screenName: ScreenNamesEnum.operationalsDates,
    childFrom: ScreenNamesEnum.financial,
    index: 22
  },
  {
    routeName: routesNames.fiscalDocuments,
    screenName: ScreenNamesEnum.fiscalDocuments,
    childFrom: ScreenNamesEnum.financial,
    index: 23
  },
  {
    routeName: routesNames.transferCancellation,
    screenName: ScreenNamesEnum.transferCancellation,
    childFrom: null,
    index: 24
  },
  {
    routeName: routesNames.secondTransfer,
    screenName: ScreenNamesEnum.secondTransfer,
    childFrom: ScreenNamesEnum.transferCancellation,
    index: 25
  },
  {
    routeName: routesNames.simulator,
    screenName: ScreenNamesEnum.simulation,
    childFrom: null,
    index: 26
  },
  {
    routeName: routesNames.faq,
    screenName: ScreenNamesEnum.faq,
    childFrom: null,
    index: 27
  }
];

export const routesWithExtraRoutes: routeScreensModelType[] = [
  ...routeScreensModel,
  {
    routeName: routesNames.detailsStudent,
    screenName: ScreenNamesEnum.detailsStudent,
    childFrom: ScreenNamesEnum.searchStudents,
    index: 90
  }
];
