import { useEffect, useState, useCallback } from 'react';
import Container from '@/base/Container';
import { useRouter } from 'next/router';
import { FormattedMessage, useFormattedMessage } from '@/shared/translation';
import Loading from '@/base/Loading';
import Alert, { IAlert } from '@/base/Alert';
import { AlertEnum } from '@/enum/AlertEnum';
import ButtonComponentSubmit from '@/base/ButtonComponentSubmit';
import Login from '@/widgets/Login';
import { errorMapping, getHttpErrorMessage } from '@/handlers/errors/handleErrorMapping';
import useRegisterLoginForm, { loginFormFields } from '@/hooks/form/useRegisterLoginForm';
import { useUserLogged } from '@/hooks/auth/useUserLogged';
import { routesNames } from '@/constants/routesNames';
import InputText, { InputTextTypeEnum } from '@/base/v2/InputText';
import AuthProvider from '@/providers/AuthProvider';
import Link from 'next/link';
import { IPermissionsFactory } from '@/factories/auth/PermissionsFactory';
import { findFirstParentFromPath } from '@/helpers/menu/findFirstParentFromPath';
import { NEXT_PUBLIC_INSIGHTS_SCREEN_IS_ENABLED } from '@/constants/envs';


const MAIN_ROUTER = NEXT_PUBLIC_INSIGHTS_SCREEN_IS_ENABLED ? routesNames.insights : routesNames.dashboard;

function getRouteToRedirect(permissions: IPermissionsFactory, redirectUrl?: string) {
  if (redirectUrl && redirectUrl === routesNames.empty) return MAIN_ROUTER;
  const urlWithoutQuery = redirectUrl?.split('?')[0];
  if (redirectUrl && Object.values(routesNames).includes(urlWithoutQuery)) {
    return redirectUrl;
  }
  if (!permissions.permissions.some((item) => item.allowedUri === MAIN_ROUTER)) {
    return findFirstParentFromPath(permissions.permissions[0].allowedUri) as string;
  }
  return MAIN_ROUTER;
}

const LoginComponent = () => {
  const [messageAlert, setMessageAlert] = useState<IAlert>({ message: '', type: null });
  const [loading, setLoading] = useState<boolean>(false);

  const { handleSubmit, control, isSubmitting, isValid, errors } = useRegisterLoginForm();
  const router = useRouter();
  const { logout, redirectUrl } = router.query;
  const intl = useFormattedMessage();
  const disableButton = !isValid || Object.keys(errors).length > 0 || isSubmitting;
  const { setUserLogged } = useUserLogged();
  const { push } = useRouter();

  useEffect(() => {
    if (logout === 'expired') {
      setMessageAlert({
        message: intl.formatMessage({ id: 'error.expiredAccess' }),
        type: AlertEnum.error
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logout]);

  const onSubmit = useCallback(async (data: loginFormFields) => {
    setLoading(true);
    setMessageAlert({ message: '', type: null });

    await AuthProvider.signIn(data)
      .then((response) => {
        const permissions = response.permissions[0];
        setUserLogged({
          permissions: response.permissions,
          user: {
            name: response.user.data.name,
            email: response.user.data.email,
            username: data.username,
            document: response.document
          },
          isAnimaRobot: response.isAnimaRobot,
          token: response.user.token,
          activeRole: response.permissions[0]
        });
        setMessageAlert({
          message: intl.formatMessage({ id: 'text.loginSuccess' }),
          type: AlertEnum.success
        });
        push(getRouteToRedirect(permissions, redirectUrl as string));
      })
      .catch((error) => {
        setMessageAlert({
          message: getHttpErrorMessage({ error }, errorMapping.login.access),
          type: AlertEnum.error
        });
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Login onSubmit={onSubmit} handleSubmit={handleSubmit} title="title.welcome" subtitle="label.loginToAcess">
      <Container className="cursor-text">
        <InputText
          label={intl.formatMessage({ id: 'text.username' })}
          type={InputTextTypeEnum.Text}
          name="username"
          mask={['username', 'email', 'document']}
          control={control}
          placeholder={intl.formatMessage({
            id: 'text.typeUsername'
          })}
          required
          className="mb-3"
          error={errors?.username?.message}
        />
        {errors?.username?.type === 'required' && (
          <p className="error">
            <FormattedMessage id="error.requiredField" />
          </p>
        )}
      </Container>

 
      <Container className="cursor-text">
        <InputText
          label={intl.formatMessage({ id: 'text.typePassword' })}
          type={InputTextTypeEnum.Password}
          autoComplete="current-password"
          name="password"
          control={control}
          placeholder={intl.formatMessage({
            id: 'text.typePassword'
          })}
          required
          className="mb-3"
          error={errors?.password?.message}
        />
        {errors?.password?.type === 'required' && (
          <p className="error">
            <FormattedMessage id="error.requiredField" />
          </p>
        )}
      </Container>
      <div className="text-right text-sm text-secondary font-medium font-pravalerText">
        <Link href={routesNames.recoverPassword}>Esqueci a senha</Link>
      </div>
      <Container className="pb-5">
        {!!messageAlert.type && (
          <Alert type={messageAlert.type} message={messageAlert.message} cleanAlert={setMessageAlert} />
        )}
        {loading ? (
          <Loading size="3rem" />
        ) : (
          <ButtonComponentSubmit
            className="flex items-center justify-center bg-secondary hover:bg-black transition duration-300 ease-in w-full py-3 text-white font-medium text-base mt-5"
            disabled={disableButton}
          >
            <FormattedMessage id="btn.login" />
          </ButtonComponentSubmit>
        )}
      </Container>
    </Login>
  );
};

export default LoginComponent;
