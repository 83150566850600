export const getSession = <DataType = any>(key: string): DataType => {
  try {
    const session = sessionStorage.getItem(key);
    return session ? JSON.parse(session) : null;
  } catch (error) {
    return null;
  }
};

export const setSession = <DataType = any>(key: string, value: DataType): void => {
  try {
    sessionStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    throw new Error(error);
  }
};

export const destroySession = (key: string): void => {
  try {
    sessionStorage.removeItem(key);
  } catch (error) {
    throw new Error(error);
  }
};
