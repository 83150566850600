import { WarningIcon, ConfirmedIcon, OutlineWarningIcon } from '@/shared/icons';
import { AlertEnum } from '@/enum/AlertEnum';
import ButtonComponent from '@/base/ButtonComponent';
import Container from '@/base/Container';
import { ReactNode } from 'react';

export type IAlert = {
  type: AlertEnum | keyof typeof AlertEnum;
  message: string | ReactNode;
  cleanAlert?: Function;
};

const IconAlert = ({ type }: { type: AlertEnum | keyof typeof AlertEnum }) => {
  switch (type) {
    case AlertEnum.error:
      return <WarningIcon className="mr-2" size={30} />;
    case AlertEnum.warning:
      return <OutlineWarningIcon className="mr-2" size={20} />;
    default:
      return <ConfirmedIcon className="mr-2" size={20} />;
  }
};

const Alert = ({ type, message, cleanAlert = null }: IAlert) => {
  const badgeColor = {
    error: 'bg-red-600',
    success: 'bg-green-600',
    warning: 'bg-[#ffbc00]'
  };

  return (
    <Container
      className={`flex relative items-center rounded text-white text-xs font-semibold px-4 py-3 my-3 ${badgeColor[type]}`}
      role="alert"
    >
      {cleanAlert && (
        <ButtonComponent
          className={`absolute -right-2 -top-3 ${badgeColor[type]} text-white shadow rounded-full text-xs font-semibold px-2 py-1`}
          onClick={() => cleanAlert('')}
        >
          X
        </ButtonComponent>
      )}
      <IconAlert type={type} />
      <div>{message}</div>
    </Container>
  );
};

export default Alert;
